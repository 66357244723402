<template>
    <div>
        <Breadcrumbs :type="type" :header="title" :url="url"></Breadcrumbs>
        <div class="max-w-3xl bg-white rounded-md shadow overflow-hidden">
            <form @submit.prevent="store" enctype="multipart/form-data">
                <div class="flex flex-wrap -mb-8 -mr-6 p-8">
                    <text-input v-model="form.first_name" :error="form.errors.first_name"
                        class="pb-8 pr-6 w-full lg:w-1/2" label="First Name" required="true" placeholder="i.e John " />
                    <text-input v-model="form.middle_name" :error="form.errors.middle_name"
                        class="pb-8 pr-6 w-full lg:w-1/2" label="Middle Name" placeholder="i.e Alden " />
                    <text-input v-model="form.last_name" :error="form.errors.last_name"
                        class="pb-8 pr-6 w-full lg:w-1/2" label="Last Name" required="true" placeholder="i.e Doe" />
                    <text-input v-model="form.surfix" :error="form.errors.surfix" class="pb-8 pr-6 w-full lg:w-1/2"
                        label="Suffix" placeholder="i.e Jr" />
                    <text-input v-model="form.employee_id" :error="form.errors.employee_id"
                        class="pb-8 pr-6 w-full lg:w-1/2" label="Employee ID" required="true" placeholder="i.e 1234" />

                    <select-input v-model="form.employee_role" :error="form.errors.employee_role"
                        class="pb-8 pr-6 w-full lg:w-1/2" label="Employee Role" required="true">
                        <option value=null>Select Role</option>
                        <option v-for="employee_role in employee_roles" :key="employee_role.id"
                            :value='employee_role.id'>{{ employee_role.role_name }}</option>
                    </select-input>

                    <select-input v-model="form.gender_id" :error="form.errors.gender_id"
                        class="pb-8 pr-6 w-full lg:w-1/2" label="Gender" required="true">
                        <option value=''>Select Gender</option>
                        <option v-for="gender in genders" :key="gender.id" :value='gender.id'>{{ gender.gender_name }}
                        </option>
                    </select-input>
                    <text-date-picker v-model="form.dob" :error="form.errors.dob" class="pb-8 pr-6 w-full lg:w-1/2"
                        label="Date of Birth" placeholder="i.e 01-01-2022" />
                    <text-input v-model="form.father_name" :error="form.errors.father_name"
                        class="pb-8 pr-6 w-full lg:w-1/2" label="Father's Name" placeholder="i.e John Ivan" />
                    <text-input v-model="form.mother_name" :error="form.errors.mother_name"
                        class="pb-8 pr-6 w-full lg:w-1/2" label="Mother's Name" placeholder="i.e Kate William" />
                    <select-input v-model="form.issued_type_id" :error="form.errors.issued_type_id"
                        class="pb-8 pr-6 w-full lg:w-1/2" label="Government Issued ID Type" required="true">
                        <option value=null>Select Government Issued ID Type</option>
                        <option v-for="governmentIssued in governmentIssuedIdType" :key="governmentIssued.id"
                            :value='governmentIssued.id'>{{ governmentIssued.name }}</option>
                    </select-input>
                    <text-input v-model="form.nid" :error="form.errors.nid" class="pb-8 pr-6 w-full lg:w-1/2"
                        label="Government Issued ID" placeholder="i.e 1234567890123" required="true" />

                    <text-input-email v-model="form.email" :error="form.errors.email" :type="email"
                        class="pb-8 pr-6 w-full lg:w-1/2" label="Email" required="true"
                        placeholder="i.e example@test.com" />
                    <text-input v-model="form.mobile" :error="form.errors.mobile" :type="text"
                        class="pb-8 pr-6 w-full lg:w-1/2" label="Mobile" required="true" placeholder="i.e 63212345678"
                        @keyup="validatePhoneNumber" />
                    <select-input v-model="form.religion_id" :error="form.errors.religion_id"
                        class="pb-8 pr-6 w-full lg:w-1/2" label="Religion" required="true">
                        <option value=''>Select Religion</option>
                        <option v-for="religion in religions" :key="religion.id" :value='religion.id'>{{ religion.name
                            }}</option>
                    </select-input>
                    <select-input v-model="form.marital_status_id" :error="form.errors.marital_status_id"
                        class="pb-8 pr-6 w-full lg:w-1/2" label="Marital Status">
                        <option value=''>Select Marital Status</option>
                        <option v-for="marital_status in marital_statuses" :key="marital_status.id"
                            :value='marital_status.id'>{{ marital_status.marital_statuse_name }}</option>
                    </select-input>

                    <!-- <div >
              <label class="form-label" :for="id">Date of Joining</label>
              <Datepicker v-model="form.date_of_joining" :enable-time-picker="false" ></Datepicker>
              <div v-if="error" class="form-error">{{ error }}</div>
            </div> -->
                    <text-date-picker v-model="form.date_of_joining" :error="form.errors.date_of_joining"
                        class="pb-8 pr-6 w-full lg:w-1/2" label="Date of Joining" required="true"
                        placeholder="i.e 01-01-2022" />

                    <text-date-picker v-model="form.date_of_retirement" :error="form.errors.date_of_retirement"
                        class="pb-8 pr-6 w-full lg:w-1/2" label="Date of Retirement" placeholder="i.e 01-01-2022" />

                    <textarea-input v-model="form.pre_address" :error="form.errors.pre_address"
                        class="pb-8 pr-6 w-full lg:w-1/2" label="Present Address" required="true"
                        placeholder="i.e Huwit-Huwit, Omar" />

                    <textarea-input v-model="form.per_address" :error="form.errors.per_address"
                        class="pb-8 pr-6 w-full lg:w-1/2" label="Permanent Address" required="true"
                        placeholder="i.e Huwit-Huwit, Omar" />

                    <file-input v-model="form.picture" :error="form.errors.picture" class="pb-8 pr-6 w-full lg:w-1/2"
                        type="file" accept="image/*" label="Profile Picture" />
                    <file-input v-model="form.signature" :error="form.errors.signature"
                        class="pb-8 pr-6 w-full lg:w-1/2" type="file" accept="image/*" label="Signature" />

                    <div class="pb-8 pr-6 w-full lg:w-1/2"></div>
                    <status-toogle-button v-model="form.email_notification" :error="form.errors.email_notification"
                        label="Email Notification" class="pb-8 pr-6 w-full lg:w-1/2 "></status-toogle-button>
                    <status-toogle-button v-model="form.sms_notification" :error="form.errors.sms_notification"
                        label="SMS Notification" class="pb-8 pr-6 w-full lg:w-1/2 "></status-toogle-button>

                    <status-toogle-button v-model="form.status" :error="form.errors.status" label="Status of Employee"
                        class="pb-8 pr-6 w-full lg:w-1/2 "></status-toogle-button>
                </div>
                <div class="flex items-center justify-end px-8 py-4 bg-gray-50 border-t border-gray-100">
                    <loading-button :loading="form.processing" class="btn-indigo" type="submit">Create
                        Employee</loading-button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { ref, toRefs, inject } from 'vue'
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import axios from 'axios';
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import TextInputEmail from '@/Shared/TextInputEmail'
import SelectInput from '@/Shared/SelectInput'
import LoadingButton from '@/Shared/LoadingButton'
import StatusToogleButton from '@/Shared/StatusToogleButton'
import Breadcrumbs from '@/Shared/Breadcrumbs'
import SelectInputFunction from '@/Shared/SelectInputFunction'
import TextDatePicker from '@/Shared/TextDatePicker'
import TextareaInput from '@/Shared/TextareaInput'
import Button from '@/Shared/Button'
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import FileInput from '@/Shared/FileInput'
export default {
    components: {
        Head,
        Link,
        LoadingButton,
        SelectInput,
        TextInput,
        StatusToogleButton,
        Breadcrumbs,
        SelectInputFunction,
        TextDatePicker,
        Button,
        TextInputEmail,
        Datepicker,
        TextareaInput,
        FileInput
    },
    layout: Layout,
    remember: 'form',
    props: {
        employee_types: Array,
        genders: Array,
        religions: Array,
        marital_statuses: Array,
        governmentIssuedIdType: Array,
        employee_roles: Array
    },
    setup(props) {
        const { genders, employee_types, religions, marital_statuses, governmentIssuedIdType, employee_roles } = toRefs(props)
        const form = useForm({
            first_name: null,
            middle_name: null,
            last_name: null,
            surfix: null,
            gender_id: '',
            date_of_joining: null,
            date_of_retirement: null,
            employee_id: null,
            employee_type_id: '',
            father_name: null,
            mother_name: null,
            nid: null,
            dob: null,
            religion_id: '',
            email: null,
            mobile: null,
            marital_status_id: '',
            status: true,
            pre_address: null,
            per_address: null,
            issued_type_id: null,
            picture: null,
            signature: null,
            employee_role: null,
            email_notification: null,
            sms_notification: null
        })
        const provinces = ref([])
        const municipality = ref([])
        const type = ref('create')
        const title = ref('Employee')
        const url = ref('/backend/employees')
        const createUrl = ref('')
        const swal = inject('$swal')
        const phoneError = ref('')

        const validatePhoneNumber = () => {
            // Regex for Philippine phone numbers without country code
            const phoneRegex = /^63\d{9}$/;
            if (form.mobile === '') {
                form.errors.mobile = 'Phone number is required.';
            } else if (!phoneRegex.test(form.mobile)) {
                form.errors.mobile = 'Invalid phone number.';
            } else {
                form.errors.mobile = '';
            }
        }
        const store = () => {
            swal({
                title: 'Are you sure?',
                text: "You want to proceed with this action",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
            }).then((result) => {
                if (result.isConfirmed) {
                    form.post(`${url.value}`, { forceFormData: true, })
                    Swal.fire(
                        'Added!',
                        'Your infomation has been Added.',
                        'success'
                    )
                }
            });

        }
        return {
            form,
            genders,
            type,
            title,
            url,
            createUrl,
            store,
            employee_types,
            religions,
            marital_statuses,
            governmentIssuedIdType,
            employee_roles,
            validatePhoneNumber,
            phoneError
        }
    },
}
</script>
