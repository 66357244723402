<template>
    <div class="profile_edit_content_block">

        <Breadcrumbs :type="type" :header="title" :url="url"></Breadcrumbs>
        <div class="w-full bg-white rounded-md shadow overflow-hidden edit_form_block">
            <form @submit.prevent="store">
                <div class="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 mt-3 p-4">
                    <template v-if="host_selected == false">
                        <select-input-function v-model="form.host_id" :error="form.errors.host_id" class="sm:col-span-2"
                            label="Host" required="true" :action="loadResident">
                            <option :value=null>Select Host</option>
                            <option v-for="host in hosts" :key="host.id" :value='host.id'>{{ host.full_name }}</option>
                        </select-input-function>
                    </template>
                    <select-input v-model="form.resident_id" :error="form.errors.resident_id" class="sm:col-span-2"
                        label="Resident" required="true">
                        <option :value=null>Select Resident</option>
                        <option v-for="resident in residentList" :key="resident.id" :value='resident.id'>{{
                            resident.title }}</option>
                    </select-input>
                    <text-input v-model="form.post_title" :error="form.errors.post_title" class="sm:col-span-2"
                        label="Title" required="true" placeholder="i.e. Anitya Cave House" @keyup="slugBuild" />
                    <text-input v-model="form.slug" :error="form.errors.slug" class="sm:col-span-2" label="Slug"
                        required="true" placeholder="i.e anitya-cave-house" />
                    <textarea-input v-model="form.post_desc" :error="form.errors.post_desc" class="sm:col-span-2"
                        label="Post Description" required="true"
                        placeholder="i.e In the heart of Cappadocia, Ortahisar, a cavehouse of 80 square meter, fully equipped kitchen with a scenic terrace is offered for a relaxing and enjoyable vacation." />
                    <text-input v-model="form.cancelation_time" :error="form.errors.cancelation_time"
                        class="sm:col-span-2" :label="'Cancellation Time (Minute)-' + cantime" placeholder="i.e. 5"
                        :type="'number'" oninput="this.value = Math.abs(this.value)" min="0" />
                    <text-input v-model="form.free_cancelation" :error="form.errors.free_cancelation"
                        class="sm:col-span-2" label=" Fee of Cancellation (Percentage)" placeholder="i.e. 5"
                        :type="'number'" oninput="this.value = Math.abs(this.value)" min="0" />
                    <text-input v-model="form.maximum_guest" :error="form.errors.maximum_guest" class="sm:col-span-2"
                        label="Maximum Guest" placeholder="i.e. 4" :type="'number'"
                        oninput="this.value = Math.abs(this.value)" min="0" />
                    <text-input v-model="form.maximum_infants" :error="form.errors.maximum_infants"
                        class="sm:col-span-2" label="Maximum Infants" placeholder="i.e. 4" :type="'number'"
                        oninput="this.value = Math.abs(this.value)" min="0" />

                    <text-input v-model="form.maximum_children" :error="form.errors.maximum_children"
                        class="sm:col-span-2" label="Maximum Children" placeholder="i.e. 4" :type="'number'"
                        oninput="this.value = Math.abs(this.value)" min="0" />

                    <text-input v-model="form.maximum_pet" :error="form.errors.maximum_pet" class="sm:col-span-2"
                        label="Maximum Pet" placeholder="i.e. 4" :type="'number'"
                        oninput="this.value = Math.abs(this.value)" min="0" />
                    <text-input v-model="form.max_boking_day" :error="form.errors.max_boking_day" class="sm:col-span-2"
                        label="Max Booking Day" placeholder="i.e. 4" oninput="this.value = Math.abs(this.value)" min="0"
                        :type="'number'" />
                    <text-input v-model="form.min_boking_day" :error="form.errors.min_boking_day" class="sm:col-span-2"
                        label="Min Booking Day" placeholder="i.e. 1" oninput="this.value = Math.abs(this.value)" min="0"
                        :type="'number'" />
                    <status-toogle-button v-model="form.restricted" :error="form.errors.restricted" label="Restricted"
                        class="sm:col-span-2 flex items-center mt-4"></status-toogle-button>

                    <template v-if="form.restricted == true">
                        <text-input :type="'date'" v-model="form.start_date" :error="form.errors.start_date"
                            class="sm:col-span-2" label="Start Date" placeholder="i.e. 1" />
                        <text-input :type="'date'" v-model="form.end_date" :error="form.errors.end_date"
                            class="sm:col-span-2" label="End Date" placeholder="i.e. 1" />
                    </template>
                    <TextareaInput v-model="form.note" :error="form.errors.note" class="sm:col-span-2" label="Note"
                        placeholder="i.e. More details, refund policy and many more" />


                    <text-input v-model="form.email" :error="form.errors.email" class="sm:col-span-2" label="Email"
                        :type="email" required="true" placeholder="i.e example@test.com" />
                    <text-input v-model="form.mobile" :error="form.errors.mobile" class="sm:col-span-2" label="Mobile"
                        placeholder="i.e +63-2-1234-5678" />
                    <status-toogle-button v-model="form.status" :error="form.errors.status" label="Post Status"
                        class="sm:col-span-2 flex items-center mt-4"></status-toogle-button>
                    <div class="sm:col-span-3 grid grid-cols-2 gap-y-2 gap-x-1 mb-1">
                        <label class="block text-sm font-medium text-gray-700 dark:text-gray-400">Amenity</label>
                        <Button :name="'Add'" :icon="'far fa-plus-circle'" :method="open_aminety_popup"></Button>
                        <div class="col-span-2 grid grid-cols-2 gap-y-2 gap-x-1 mb-1 border-t border-gray-300 p-3">
                            <ul class="flex  flex-wrap gap-2 col-span-2">
                                <li class="flex items-center w-auto c-list px-2 py-1"
                                    v-for="(amenity, n_index) in form.amenities" v-if="form.amenities.length > 0">
                                    {{ aminety_title(amenity) }}
                                </li>
                                <li v-else class="w-full py-2 italic">
                                    <span class="far fa-circle-info"></span>
                                    No Amenity Selected
                                </li>
                            </ul>
                        </div>
                        <div class="col-span-2">
                            <amenity :amenities="form.amenities" :amenityopen="amenityopen"
                                @UpdateAminetyPopup="update_aminety_popup" @UpdateAminety="update_aminety">
                            </amenity>
                        </div>
                    </div>
                    <div class="sm:col-span-3 grid grid-cols-2 gap-y-2 gap-x-1 mb-1">
                        <label class="block text-sm font-medium text-gray-700 dark:text-gray-400">Category <span
                                class="custom-error">*</span></label>
                        <Button :name="'Add'" :icon="'far fa-plus-circle'" :method="open_category_popup"></Button>
                        <div class="col-span-2 grid grid-cols-2 gap-y-2 gap-x-1 mb-1 border-t border-gray-300 p-3">
                            <ul class="flex  flex-wrap gap-2 col-span-2">
                                <li class="flex items-center w-auto c-list px-2 py-1"
                                    v-for="(category, n_index) in form.categories" v-if="form.categories.length > 0">
                                    {{ category_title(category) }}
                                </li>
                                <li v-else class="w-full py-2 italic">
                                    <span class="far fa-circle-info"></span>
                                    No Category Selected
                                </li>
                            </ul>
                        </div>
                        <div class="col-span-2">
                            <category :categories="form.categories" :categoryopen="categoryopen"
                                @UpdateCategoryPopup="update_category_popup" @UpdateCategory="update_category">
                            </category>
                        </div>
                        <div v-if="form.errors.categories" class="form-error">Category required.</div>
                    </div>
                    <div class="sm:col-span-3 grid grid-cols-2 gap-y-2 gap-x-1 mb-1">
                        <label class="block text-sm font-medium text-gray-700 dark:text-gray-400">Keyword</label>
                        <Button :name="'Add'" :icon="'far fa-plus-circle'" :method="open_keyword_popup"></Button>
                        <div class="col-span-2 grid grid-cols-2 gap-y-2 gap-x-1 mb-1 border-t border-gray-300 p-3">
                            <ul class="flex  flex-wrap gap-2 col-span-2">
                                <li class="flex items-center w-auto c-list px-2 py-1"
                                    v-for="(keyword, n_index) in form.keywords" v-if="form.keywords.length > 0">
                                    {{ keyword_title(keyword) }}
                                </li>
                                <li v-else class="w-full py-2 italic">
                                    <span class="far fa-circle-info"></span>
                                    No keyword Selected
                                </li>
                            </ul>
                        </div>
                        <div class="col-span-2">
                            <keyword :keywords="form.keywords" :keywordopen="keywordopen"
                                @UpdateKeywordPopup="update_keyword_popup" @UpdateKeyword="update_keyword">
                            </keyword>
                        </div>
                    </div>
                    <div class="sm:col-span-3 grid grid-cols-2 gap-y-2 gap-x-1 mb-1">
                        <label class="block text-sm font-medium text-gray-700 dark:text-gray-400">Property Type</label>
                        <Button :name="'Add'" :icon="'far fa-plus-circle'" :method="open_property_type_popup"></Button>
                        <div class="col-span-2 grid grid-cols-2 gap-y-2 gap-x-1 mb-1 border-t border-gray-300 p-3">
                            <ul class="flex  flex-wrap gap-2 col-span-2">
                                <li class="flex items-center w-auto c-list px-2 py-1"
                                    v-for="(property_type, n_index) in form.property_types"
                                    v-if="form.property_types.length > 0">
                                    {{ property_type_title(property_type) }}
                                </li>
                                <li v-else class="w-full py-2 italic">
                                    <span class="far fa-circle-info"></span>
                                    No property type Selected
                                </li>
                            </ul>
                        </div>
                        <div class="col-span-2">
                            <property-type :property_types="form.property_types" :property_typeopen="property_typeopen"
                                @UpdatePropertyTypePopup="update_property_type_popup"
                                @UpdatePropertyType="update_property_type">
                            </property-type>
                        </div>
                    </div>
                    <div class="sm:col-span-3 grid grid-cols-2 gap-y-2 gap-x-1 mb-1">
                        <label class="block text-sm font-medium text-gray-700 dark:text-gray-400">Attachment</label>
                        <Button :name="'Add'" :icon="'far fa-plus-circle'" :method="open_attachment_popup"></Button>
                        <div class="col-span-2 grid grid-cols-2 gap-y-2 gap-x-1 mb-1 border-t border-gray-300 p-3">
                            <ul class="flex  flex-wrap gap-2 col-span-2">
                                <li class="flex items-center w-auto c-list px-2 py-1"
                                    v-for="(attachment, n_index) in form.attachments"
                                    v-if="form.attachments.length > 0">
                                    {{ attachment_title(attachment) }}
                                </li>
                                <li v-else class="w-full py-2 italic">
                                    <span class="far fa-circle-info"></span>
                                    No Attachment Selected
                                </li>
                            </ul>
                        </div>
                        <div class="col-span-2">
                            <attachment :attachments="form.attachments" :attachmentopen="attachmentopen"
                                @UpdateAttachmentPopup="update_attachment_popup" @UpdateAttachment="update_attachment">
                            </attachment>
                        </div>
                    </div>
                    <div class="sm:col-span-3 grid grid-cols-2 gap-y-2 gap-x-1 mb-1">
                        <div class="flex items-center col-span-2">
                            <label class="block text-sm font-medium text-gray-700 dark:text-gray-400">Rooms</label>
                            <Button :name="'Add'" :icon="'far fa-plus-circle'" :method="open_room_popup"></Button>
                        </div>
                        <div class="col-span-2 grid grid-cols-2 gap-y-2 gap-x-1 mb-1 border-t border-gray-300 p-3">
                            <ul class="grid  gap-3 col-span-2">
                                <li class="w-auto rounded-md border- border-gray-300"
                                    v-for="(room, n_index) in form.rooms" v-if="form.rooms.length > 0">
                                    <div class="flex align-middle item_block  border-b-2 border-gray-200">
                                        <div class="p-2 inline-block align-middle">
                                            <img :src="room.image_path" alt="profile" class="w-12 h-10 rounded-full"
                                                v-if="room.image_path != null">
                                            <span v-else class="align-middle fa-duotone fa-image font-30 p-2"></span>
                                        </div>
                                        <div class="w-full relative p-2">
                                            <i class="fa-duotone fa-trash right-0 text-red-700 top-1 absulate "
                                                @click="removeRooms(n_index)"></i>
                                            <p>{{ room_type_title(room.room_id) }}</p>
                                            <p>{{ room.room_title }}</p>
                                        </div>
                                    </div>
                                    <button @click="open_accessories_popup(room, n_index)"
                                        class="flex items-center gap-1 ml-auto text-[11px] font-semibold p-2"
                                        type="button" data-v-273d79e2=""><span class="far fa-plus-circle"></span>
                                        Add</button>
                                    <!-- <span @click="open_accessories_popup(room,n_index)">Add</span> -->
                                    <div class="grid grid-cols-4">
                                        <div class="flex align-middle item_block"
                                            v-for="(accessory, m_index) in room.accessories"
                                            v-if="room.accessories.length > 0">
                                            <div class="p-1 inline-block align-middle">
                                                <img :src="accessory.image" alt="profile" class="w-12 h-10 rounded-full"
                                                    v-if="accessory.image != null">
                                                <span v-else-if="accessory.icon != null"
                                                    :class="accessory.icon + 'align-middle font-15 p-1'"></span>
                                                <span v-else
                                                    class="align-middle fa-duotone fa-image font-15 p-2"></span>
                                            </div>
                                            <div class="w-full relative p-1 flex items-center">
                                                <i class="fa-duotone fa-trash right-0 text-red-700 top-1 absulate "
                                                    @click="removeAccessories(n_index, m_index)"></i>
                                                <p>{{ accessories_title(accessory.bed_id) }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li v-else class="w-full py-2 italic">
                                    <span class="far fa-circle-info"></span>
                                    No Room Selected
                                </li>
                            </ul>
                        </div>
                        <div class="col-span-2">
                            <room :rooms="form.rooms" :roomopen="roomopen" @UpdateRoomPopup="update_room_popup"
                                @UpdateRoom="update_room">
                            </room>
                        </div>
                    </div>
                    <div class="sm:col-span-3 grid grid-cols-2 gap-y-2 gap-x-1 mb-1">
                        <div class="flex items-center col-span-2">
                            <label class="block text-sm font-medium text-gray-700 dark:text-gray-400">Meta</label>
                            <Button :name="'Add'" :icon="'far fa-plus-circle'" :method="open_meta_popup"></Button>
                        </div>
                        <div class="col-span-2 grid grid-cols-2 gap-y-2 gap-x-1 my-1">
                            <ul class="grid grid-cols-2 gap-3 col-span-2">
                                <li class="w-auto rounded-md border border-gray-300"
                                    v-for="(meta, n_index) in form.meta_infos" v-if="form.meta_infos.length > 0">
                                    <div class="flex align-middle item_block">
                                        <div class="p-2 inline-block align-middle">
                                            <img :src="meta.image" alt="profile" class="w-12 h-10 rounded-full"
                                                v-if="meta.image != null">
                                            <span v-else class="align-middle fa-duotone fa-image font-30 p-2"></span>
                                        </div>
                                        <div class="w-full relative p-2">
                                            <i class="fa-duotone fa-trash right-0 text-red-700 top-1 absulate "
                                                @click="removeMeta(n_index)"></i>

                                            <p>{{ meta.title }}</p>
                                            <p>{{ meta.description }}</p>
                                        </div>
                                    </div>
                                </li>
                                <li v-else class="w-full border-t border-gray-300  p-4 col-span-2 italic">
                                    <span class="far fa-circle-info"></span>
                                    No Meta Selected
                                </li>
                            </ul>
                        </div>
                        <div class="col-span-2">
                            <meta-info :meta_infos="form.meta_infos" :metaopen="metaopen"
                                @UpdateMetaPopup="update_meta_popup" @UpdateMeta="update_meta">
                            </meta-info>
                        </div>
                    </div>
                    <div class="sm:col-span-3 grid grid-cols-2 gap-y-2 gap-x-1 mb-1">
                        <div class="flex items-center col-span-2">
                            <label class="block text-sm font-medium text-gray-700 dark:text-gray-400">Gallery <span
                                    class="custom-error">*</span></label>
                            <Button :name="'Add'" :icon="'far fa-plus-circle'" :method="open_gallery_popup"></Button>
                        </div>
                        <div class="col-span-2 gap-y-2 gap-x-1 my-1">
                            <!-- <ul class="flex flex-wrap items-center gap-4">
                                <li class="flex items-center w-auto h-full rounded-md border border-gray-300"
                                    v-for="(gallery, n_index) in form.galleries" v-if="form.galleries.length > 0">
                                    <div class="flex align-middle item_block">
                                        <div class="relative" v-if="gallery.image_path != null">
                                            <img :src="gallery.image_path" alt="profile" class="h-[150px]">
                                            <i class="fa-light fa-xmark right-0 text-red-700 top-1 absulate del"
                                                @click="removeGallery(n_index)"></i>
                                        </div>
                                        <span v-else class="align-middle fa-duotone fa-image font-30 p-2"></span>
                                    </div>
                                </li>
                                <li v-else class="w-full  border-t border-gray-300  p-4 col-span-2 italic">
                                    <span class="far fa-circle-info"></span>
                                    No
                                    Gallery Selected </li>
                            </ul> -->

                            <gallery-view :galleries="form.galleries" @UpdateGalleryList="update_gallery_list" />

                        </div>
                        <div class="col-span-2">
                            <gallery :galleries="form.galleries" :galleryopen="galleryopen"
                                @UpdateGalleryPopup="update_gallery_popup" @UpdateGallery="update_gallery">
                            </gallery>
                        </div>
                        <div v-if="form.errors.galleries" class="form-error">Gallery required.</div>
                    </div>
                    <div class="sm:col-span-3 grid grid-cols-2 gap-y-2 gap-x-1 mb-1">
                        <div class="flex items-center col-span-2">
                            <label class="block text-sm font-medium text-gray-700 dark:text-gray-400">Property Stay
                                Activity
                                Type <span class="custom-error">*</span></label>
                            <Button :name="'Add'" :icon="'far fa-plus-circle'"
                                :method="open_activity_type_popup"></Button>
                        </div>
                        <div class="col-span-2 grid grid-cols-2 gap-y-2 gap-x-1 my-1">
                            <ul class="grid grid-cols-2 gap-3 col-span-2">
                                <li class="w-auto rounded-md border border-gray-300"
                                    v-for="(activity_type, n_index) in form.activity_types"
                                    v-if="form.activity_types.length > 0">
                                    <div style='background-color:rgb(255, 255, 255)' class="item_block">
                                        <div class="bg-white rounded p-4 shadow md:flex justify-between relative"
                                            style="cursor: auto;">
                                            <i class="fa-light fa-xmark right-0 text-red-700 top-1 absulate del"
                                                @click="removeType(n_index)"></i>
                                            <div>
                                                <h4 class="text-[18px] font-semibold">{{ activity_type.name }}-{{
                                                    activity_type_title(activity_type.activity_type_id) }}</h4>
                                                <p v-if="activity_type.price != null"><span
                                                        class="fa-duotone fa-money-bill"></span> {{
                                                            activity_type.price }}
                                                </p>
                                                <p v-if="activity_type.discount != null && activity_type.discoun > 0">
                                                    <span class="fa-duotone fa-money-bill"></span> {{
                                                        activity_type.discount }}
                                                </p>
                                                <div class="flex items-center mt-4">
                                                    <div
                                                        class="text-xs uppercase font-bold tracking-wider bg-gray-300 inline-block px-2 py-1 rounded mr-2">
                                                        <span class="fa-duotone fa-hourglass-start"></span>
                                                        {{ activity_type.checkin_time }}
                                                    </div>
                                                    <div
                                                        class="text-xs uppercase font-bold tracking-wider bg-gray-300 inline-block px-2 py-1 rounded mr-2">
                                                        <span class="fa-duotone fa-hourglass-end"></span>
                                                        {{ activity_type.checkout_time }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="text-right md:ml-8 flex items-center">
                                                <div class="flex md:block -mx-2 md:mx-0 mt-3 md:mt-0">
                                                    <div class="flex justify-end mb-1 px-2 md:px-0"
                                                        v-if="activity_type.activity_type_id == 1">
                                                        <div class="text-xl">{{ activity_type.number_of_day }}</div>
                                                        <div class="ml-2">
                                                            <span class="fa-duotone fa-house-day text-[19px]"></span>
                                                        </div>
                                                    </div>
                                                    <div class="flex justify-end items-bottom mb-1 px-2 md:px-0"
                                                        v-if="activity_type.activity_type_id == 2">
                                                        <div class="text-xl">{{ activity_type.number_of_night }}</div>
                                                        <div class="ml-2">
                                                            <span class="fa-duotone fa-house-night text-[19px]"></span>
                                                        </div>
                                                    </div>
                                                    <div class="flex justify-end px-2 md:px-0 "
                                                        v-if="activity_type.activity_type_id == 3">
                                                        <div class="text-xl">{{ activity_type.total_days }}</div>
                                                        <div class="ml-4 text-xl">
                                                            <span
                                                                class="fa-duotone fa-calendar-days  text-[19px]"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li v-else class="w-full border-t border-gray-300  p-4 col-span-2 italic">
                                    <span class="far fa-circle-info"></span>
                                    No
                                    Property Stay Activity Type Selected
                                </li>
                            </ul>
                        </div>
                        <div class="col-span-2">
                            <activity-type :activity_types="form.activity_types" :activitytypeopen="activitytypeopen"
                                @UpdateActivityPopup="update_activity_popup" @UpdateActivity="update_activity_type">
                            </activity-type>
                        </div>
                        <div v-if="form.errors.activity_types" class="form-error">Property Stay Activity Type required.
                        </div>
                    </div>
                </div>
                <Button :type="type" :name="title" :url="createUrl" :loading="form.processing"></Button>
            </form>
        </div>
        <Accessories :accessories="sel_room_accessories" :accessoriesopen="accessoriesopen"
            @UpdateAccessoriesPopup="update_accessories_popup" @UpdateAccessories="update_accessories"
            :acc_index="sel_room_index">
        </Accessories>

    </div>
</template>

<script>
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import { ref, toRefs, inject, onMounted, watch } from 'vue'
import axios from 'axios';
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import TextareaInput from '@/Shared/TextareaInput'
import SelectInput from '@/Shared/SelectInput'
import SelectInputFunction from '@/Shared/SelectInputFunction'
import LoadingButton from '@/Shared/LoadingButton'
import Breadcrumbs from '@/Shared/Breadcrumbs'
import StatusToogleButton from '@/Shared/StatusToogleButton'
import Button from '@/Shared/Button'
import amenity from './components/Amenity'
import attachment from './components/Attachment'
import category from './components/Categoty'
import keyword from './components/Keyword'
import PropertyType from './components/PropertyType'
import Room from './components/Room'
import Accessories from './components/Accessories'
import MetaInfo from './components/Meta'
import ActivityType from './components/ActivityType'
import PhotoBlock from './Photo';
import Gallery from './components/Gallery';
import GalleryView from './components/GalleryView';
import ToolTip from '@/Shared/ToolTip'
export default {
    components: {
        Head,
        Link,
        LoadingButton,
        SelectInput,
        SelectInputFunction,
        TextInput,
        Breadcrumbs,
        StatusToogleButton,
        Button,
        TextareaInput,
        PhotoBlock,
        amenity,
        category,
        keyword,
        PropertyType,
        Room,
        MetaInfo,
        Gallery,
        Accessories,
        attachment,
        ActivityType,
        ToolTip,
        GalleryView
    },
    props: {
        residents: Array,
        hosts: Array,
        activity_types: Array,
        permission: Array,
        user_role: Number,
    },
    layout: Layout,
    remember: 'form',
    setup(props) {
        const backendPrefix = ref(process.env.MIX_ADMIN_PREFIX)
        const { permission, activity_types, user_role, residents, hosts } = toRefs(props)
        const form = useForm({
            post_title: null,
            slug: null,
            post_desc: null,
            status: null,
            price_per_night: null,
            discount_price_per_night: null,
            maximum_guest: null,
            maximum_infants: null,
            maximum_children: null,
            maximum_pet: null,
            free_cancelation: null,
            cancelation_time: null,
            amenities: [],
            attachments: [],
            categories: [],
            keywords: [],
            property_types: [],
            rooms: [],
            meta_infos: [],
            galleries: [],
            activity_types: [],
            max_boking_day: null,
            min_boking_day: null,
            start_date: null,
            end_date: null,
            map_embedded: null,
            restricted: false,
            note: null,
            email: null,
            mobile: null,
            resident_id: null,
            host_id: null
        })

        const residentList = ref(residents.value)
        const type = ref('create')
        const title = ref('Post')
        const url = ref('/backend/posts')
        const createUrl = ref('')
        const amenityopen = ref(false)
        const attachmentopen = ref(false)
        const categoryopen = ref(false)
        const keywordopen = ref(false)
        const property_typeopen = ref(false)
        const roomopen = ref(false)
        const metaopen = ref(false)
        const galleryopen = ref(false)
        const accessoriesopen = ref(false)
        const activitytypeopen = ref(false)
        const sel_room_accessories = ref([])
        const sel_room_index = ref('')
        const cantime = ref('')
        const host_selected = ref(false)
        const swal = inject('$swal')
        const store = () => {
            swal({
                title: 'Are you sure?',
                text: "You want to proceed with this action",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
            }).then((result) => {
                if (result.isConfirmed) {
                    form.post(`${url.value}`, { forceFormData: true, })
                    Swal.fire(
                        'Added!',
                        'Your infomation has been Added.',
                        'success'
                    )
                }
            });

        }

        const update_aminety_popup = (val) => {
            amenityopen.value = val
        }

        const update_aminety = (val) => {
            form.amenities = val
        }

        const update_attachment_popup = (val) => {
            attachmentopen.value = val
        }
        const update_attachment = (val) => {
            form.attachments = val
        }

        const update_category_popup = (val) => {
            categoryopen.value = val
        }
        const update_category = (val) => {
            form.categories = val
        }

        const update_keyword_popup = (val) => {
            keywordopen.value = val
        }
        const update_keyword = (val) => {
            form.keywords = val
        }

        const update_property_type_popup = (val) => {
            property_typeopen.value = val
        }
        const update_property_type = (val) => {
            form.property_types = val
        }

        const update_room_popup = (val) => {
            roomopen.value = val
        }
        const update_room = (val) => {
            form.rooms = val
        }

        const update_accessories_popup = (val) => {
            accessoriesopen.value = val
        }

        const update_accessories = (val) => {
            form.rooms[sel_room_index.value].accessories.push(val)
        }

        const update_meta_popup = (val) => {
            metaopen.value = val
        }
        const update_meta = (val) => {
            form.meta_infos = val
        }

        const update_activity_popup = (val) => {
            activitytypeopen.value = val
        }
        const update_activity_type = (val) => {
            ////console.log(val.activity_type_id);
            let obj = form.activity_types.find(o => o.activity_type_id === val.activity_type_id);
            if (obj === undefined) {
                form.activity_types.push(val)
            } else {
                alert('You have already add that type');
            }

        }

        const update_gallery_popup = (val) => {
            galleryopen.value = val
        }
        const update_gallery = (val) => {
            form.gallery = val
        }

        const amenities_list = ref([])
        const load_amenities = async () => {
            await axios.post('../amenities/activeamenities')
                .then(function (response) {
                    amenities_list.value = response.data
                }.bind(this));
        }

        const attachments_list = ref([])
        const load_attachments = async () => {
            await axios.post('../serviceinfos/get/attachments')
                .then(function (response) {
                    //  //console.log(response);
                    attachments_list.value = response.data
                }.bind(this));
        }

        const category_list = ref([])
        const load_category = async () => {
            await axios.post('../categories/activecategories')
                .then(function (response) {
                    category_list.value = response.data
                }.bind(this));
        }

        const keyword_list = ref([])
        const load_keyword = async () => {
            await axios.post('../keywords/activekeywords')
                .then(function (response) {
                    keyword_list.value = response.data
                }.bind(this));
        }

        const property_type_list = ref([])
        const load_property_type = async () => {
            await axios.post('../property-types/activepropertytypes')
                .then(function (response) {
                    property_type_list.value = response.data
                }.bind(this));
        }

        const room_list = ref([])
        const load_rooms = async () => {
            await axios.post('../room-infos/activerooms')
                .then(function (response) {
                    room_list.value = response.data
                }.bind(this));
        }

        const accessories_list = ref([])
        const load_accessories = async () => {
            await axios.post('../room-accessories/activeaccessories')
                .then(function (response) {
                    accessories_list.value = response.data
                }.bind(this));
        }

        const type_list = ref([])
        const load_type = async () => {
            await axios.post('../stay-activity-types/activity-type')
                .then(function (response) {
                    type_list.value = response.data
                }.bind(this));
        }

        const activity_type_title = (value) => {
            const index = type_list.value.findIndex(p => p.id == value);
            return type_list.value[index] ? type_list.value[index].title : null;
        }

        const open_aminety_popup = () => {
            amenityopen.value = true
        }
        const open_attachment_popup = () => {
            attachmentopen.value = true
        }

        const open_category_popup = () => {
            categoryopen.value = true
        }

        const open_keyword_popup = () => {
            keywordopen.value = true
        }

        const open_property_type_popup = () => {
            property_typeopen.value = true
        }

        const open_room_popup = () => {
            roomopen.value = true
        }
        const open_accessories_popup = (data, index) => {
            sel_room_accessories.value = data.accessories
            sel_room_index.value = index
            accessoriesopen.value = true
        }

        const open_meta_popup = () => {
            metaopen.value = true
        }

        const open_activity_type_popup = () => {
            activitytypeopen.value = true
        }

        const open_gallery_popup = () => {
            galleryopen.value = true
        }


        const aminety_title = (value) => {
            const index = amenities_list.value.findIndex(p => p.id == value);
            return amenities_list.value[index].amenity_title;

        }

        const attachment_title = (value) => {
            const index = attachments_list.value.findIndex(p => p.id == value);
            return attachments_list.value[index].title;

        }

        const keyword_title = (value) => {
            const index = keyword_list.value.findIndex(p => p.id == value);
            return keyword_list.value[index].keyword_title;

        }

        const property_type_title = (value) => {
            const index = property_type_list.value.findIndex(p => p.id == value);
            return property_type_list.value[index].type_title;

        }

        const category_title = (value) => {
            const index = category_list.value.findIndex(p => p.id == value);
            return category_list.value[index].category_title;

        }

        const room_type_title = (value) => {
            if (value != null) {
                const index = room_list.value.findIndex(p => p.id == value);
                return room_list.value[index].room_title;
            }
        }

        const accessories_title = (value) => {
            if (value != null) {
                const index = accessories_list.value.findIndex(p => p.id == value);
                return accessories_list.value[index].bed_title;
            }
        }

        const removeRooms = (index) => {
            if (index !== -1) {
                form.rooms.splice(index, 1)
            }
        }
        const removeAccessories = (index, acc_index) => {
            if (index !== -1 && acc_index !== -1) {
                form.rooms[index].accessories.splice(acc_index, 1)
            }
        }

        const removeMeta = (index) => {
            if (index !== -1) {
                form.meta_infos.splice(index, 1)
            }
        }

        const removeActivityType = (index) => {
            if (index !== -1) {
                form.activity_types.splice(index, 1)
            }
        }

        const removeType = (index) => {
            if (index !== -1) {
                form.activity_types.splice(index, 1)
            }
        }

        const setHostData = () => {
            if (user_role.value != 1 && user_role.value != 10) {

                host_selected.value = true
            }
        }

        const secondsToDhms = (seconds) => {
            seconds = Number(seconds);
            var d = Math.floor(seconds / (3600 * 24));
            var h = Math.floor(seconds % (3600 * 24) / 3600);
            var m = Math.floor(seconds % 3600 / 60);
            var s = Math.floor(seconds % 60);
            var dDisplay = d > 0 ? d + (d == 1 ? " day " : " days ") : "";
            var hDisplay = h > 0 ? h + (h == 1 ? " hour " : " hours ") : "";
            var mDisplay = m > 0 ? m + (m == 1 ? " minute " : " minutes ") : "";
            var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
            cantime.value = dDisplay + hDisplay + mDisplay + sDisplay;
        }
        const slugBuild = () => {
            form.slug = form.post_title.replace(/\s/g, '-').toLowerCase()
        }
        const canceltime = () => {
            secondsToDhms(parseInt(form.cancelation_time) * 60);
        }

        const loadResident = async (e) => {
            if (e.target.value > 0) {
                let data = {
                    host_id: e.target.value
                }
                await axios.post(`/${backendPrefix.value}/tourist/get-resident`, data)
                    .then(function (response) {
                        residentList.value = response.data;
                        console.log(residentList.value);
                    }.bind(this));
            } else {
                residentList.value = [];
            }
        }
        watch(() => form.cancelation_time, () => {
            canceltime()
        }, { deep: true })

        watch(() => form.amenities, () => {
            load_amenities()
        }, { deep: true })

        watch(() => form.attachments, () => {
            load_attachments()
        }, { deep: true })

        watch(() => form.categories, () => {
            load_category()
        }, { deep: true })

        watch(() => form.keywords, () => {
            load_keyword()
        }, { deep: true })

        watch(() => form.property_types, () => {
            load_property_type()
        }, { deep: true })

        watch(() => form.rooms, () => {
            load_rooms()
        }, { deep: true })

        watch(() => form.rooms.accessories, () => {
            load_accessories();
        }, { deep: true })

        onMounted(() => {
            load_amenities()
            load_attachments()
            load_category()
            load_keyword()
            load_property_type()
            load_rooms()
            load_accessories()
            load_type()
            setHostData()
        })

        return {
            form,
            type,
            title,
            url,
            createUrl,
            store,
            permission,

            amenityopen, open_aminety_popup, update_aminety_popup, update_aminety, amenities_list, load_amenities, aminety_title,
            categoryopen, open_category_popup, update_category_popup, update_category, category_list, load_category, category_title,
            keywordopen, open_keyword_popup, update_keyword_popup, update_keyword, keyword_list, load_keyword, keyword_title,
            property_typeopen, open_property_type_popup, update_property_type_popup, update_property_type, property_type_list, load_property_type, property_type_title,
            roomopen, open_room_popup, update_room_popup, update_room, room_list, load_rooms, room_type_title, removeRooms,
            metaopen, open_meta_popup, update_meta_popup, update_meta, removeMeta,

            galleryopen, open_gallery_popup, update_gallery_popup, update_gallery,
            accessoriesopen, open_accessories_popup, update_accessories_popup, update_accessories, accessories_list, load_accessories, accessories_title, sel_room_index, removeAccessories,
            backendPrefix,
            activity_types,
            attachmentopen, open_attachment_popup, update_attachment_popup, update_attachment, attachments_list, load_attachments, attachment_title,
            activitytypeopen, open_activity_type_popup, update_activity_popup, update_activity_type, removeActivityType, type_list, load_type, activity_type_title, removeType,
            user_role, setHostData, host_selected,
            secondsToDhms,
            canceltime,
            cantime,
            slugBuild,
            residents,
            hosts,
            loadResident,
            residentList
        }
    }
}
</script>
<style lang="scss" scoped>
$button_height: 36;

.profile_edit_content_block {
    display: block;

    .edit_form_block {
        background-color: #ffffff80;

        textarea,
        select {
            width: 100%;
            border: 1px solid #ddd;
            padding: 5px 10px;
            border-radius: 5px;
        }

        .item_block:hover>div i {
            display: flex !important;
        }

        i {
            width: 30px;
            text-align: center;
            transition: all 0.4s;
            position: absolute;
            margin-right: 0px !important;
            top: 15px;
            font-size: 10px;
            display: none;
            cursor: pointer;

            &.active {
                display: flex !important;
            }

            &.del {
                position: absolute;
                width: 20px;
                height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #fff;
                border-radius: 50%;
                box-shadow: 0 0 5px #999;
                border: 1px solid #ddd;
                cursor: pointer;
                top: -5px;
                right: -5px;
            }
        }



        .err_msg {
            border-color: #CD0000 !important
        }

        .c-list {
            background: #666;
            color: #fff;
            border-radius: 15px;
            font-size: 11px;
            max-height: 24px;
        }
    }
}

.save_btn,
.cancel_btn {
    height: #{$button_height}px;
    background-color: #0f8050;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;

    &.cancel_btn {
        border: 1px solid #CD0000;
        color: #CD0000;
        background-color: #fff;
    }
}

.dark {
    .profile_edit_content_block {

        input,
        textarea,
        select {
            background-color: #222;
        }
    }
}

.password_block,
.confirm_password_block {
    p {
        align-items: baseline;
        color: #666;
        display: flex;
        gap: 5px;
        font-size: 10px !important;

        span {
            color: gold !important;
            font-size: 10px !important;
        }
    }

}
</style>
