<template>
    <div>
        <Breadcrumbs :type="type" :header="title" :url="url" :head="head"></Breadcrumbs>
        <trashed-message v-if="meta.deleted_at" class="mb-6" @restore="restore"> This News has been deleted.
        </trashed-message>
        <div class="max-w-5xl bg-white rounded-md shadow overflow-hidden">
            <form @submit.prevent="update">
                <div class="flex flex-wrap -mb-8 -mr-6 p-8">
                    <div class="pb-8 pr-6 w-full lg:w-1/2">
                        <div class="mt-1 flex items-center h-[225px]">
                            <div
                                class="mt-1 flex flex-wrap justify-center rounded-md border-2 border-dashed border-gray-300 dark:border-black dark:bg-white dark:text-black px-6 pt-5 pb-6">
                                <div class="space-y-1 text-center">
                                    <PhotoBlock :getPhoto="form.og_image" @SetPhoto="set_photo" />
                                    <span>Og Image</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="pb-8 pr-6 w-full lg:w-1/2">
                        <div class="mt-1 flex items-center h-[225px]">
                            <div
                                class="mt-1 flex flex-wrap justify-center rounded-md border-2 border-dashed border-gray-300 dark:border-black dark:bg-white dark:text-black px-6 pt-5 pb-6">
                                <div class="space-y-1 text-center">
                                    <PhotoBlock :getPhoto="form.twitter_image" @SetPhoto="set_twitter_photo" />
                                    <span>Twitter Image</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <text-input v-model="form.title" :error="form.errors.title" class="pb-8 pr-6 w-full lg:w-1/2"
                        label="Title of Meta" required="true" placeholder="i.e " />
                    <text-input v-model="form.slug" :error="form.errors.slug" class="pb-8 pr-6 w-full lg:w-1/2"
                        label="Slug of Meta" required="true" placeholder="i.e " />
                    <textarea-input v-model="form.description" :error="form.errors.description"
                        class="pb-8 pr-6 w-full lg:w-1/1" label="Description of Meta" required="true"
                        placeholder="i.e " />
                    <text-input v-model="form.og_url" :error="form.errors.og_url" class="pb-8 pr-6 w-full lg:w-1/2"
                        label="Og Url" placeholder="i.e " />
                    <text-input v-model="form.og_title" :error="form.errors.og_title" class="pb-8 pr-6 w-full lg:w-1/2"
                        label="Og Title" required="true" placeholder="i.e " />
                    <textarea-input v-model="form.og_description" :error="form.errors.og_description"
                        class="pb-8 pr-6 w-full lg:w-1/1" label="Og Description" placeholder="i.e " />
                    <text-input v-model="form.og_type" :error="form.errors.og_type" class="pb-8 pr-6 w-full lg:w-1/2"
                        label="Og Type" required="true" placeholder="i.e " />
                    <text-input v-model="form.og_pocale" :error="form.errors.og_pocale"
                        class="pb-8 pr-6 w-full lg:w-1/2" label="Og Pocale" required="true" placeholder="i.e " />
                    <text-input v-model="form.twitter_card" :error="form.errors.twitter_card"
                        class="pb-8 pr-6 w-full lg:w-1/2" label="Twitter Card" required="true" placeholder="i.e " />
                    <text-input v-model="form.twitter_title" :error="form.errors.twitter_title"
                        class="pb-8 pr-6 w-full lg:w-1/2" label="Twitter Title" required="true" placeholder="i.e " />
                    <textarea-input v-model="form.twitter_description" :error="form.errors.twitter_description"
                        class="pb-8 pr-6 w-full lg:w-1/1" label="Twitter Description" placeholder="i.e " />

                    <textarea-input v-model="form.others" :error="form.errors.others" class="pb-8 pr-6 w-full lg:w-1/1"
                        label="Others" placeholder="i.e " />
                    <status-toogle-button v-model="form.status" :error="form.errors.status" label="Meta Status"
                        class="pb-8 pr-6 w-full lg:w-1/1"></status-toogle-button>
                </div>
                <div class="flex items-center px-8 py-4 bg-gray-50 border-t border-gray-100">
                    <button v-if="!meta.deleted_at && permission.edit > 0" class="text-red-600 hover:underline"
                        tabindex="-1" type="button" @click="destroy"><delete-icon></delete-icon></button>
                    <Button v-if="permission.edit > 0" :type="type" :name="head" :url="createUrl"
                        :loading="form.processing"></Button>
                </div>
            </form>
        </div>

    </div>
</template>

<script>
import { ref, toRefs, inject } from 'vue'
import ContentBuilder from '@/Pages/Admin/ContentBuilder/Index'
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import SelectInput from '@/Shared/SelectInput'
import LoadingButton from '@/Shared/LoadingButton'
import TrashedMessage from '@/Shared/TrashedMessage'
import StatusToogleButton from '@/Shared/StatusToogleButton'
import Breadcrumbs from '@/Shared/Breadcrumbs'
import DeleteIcon from '@/Shared/DeleteIcon'
import Button from '@/Shared/Button'
import PhotoBlock from './Photo';
import TextareaInput from '@/Shared/TextareaInput'
import FileInput from '@/Shared/FileInput'
import { VueEditor, Quill } from "vue3-editor";
export default {
    components: {
        Head,
        Icon,
        Link,
        LoadingButton,
        SelectInput,
        TextInput,
        TrashedMessage,
        StatusToogleButton,
        Breadcrumbs,
        DeleteIcon,
        Button,
        TextareaInput,
        VueEditor,
        Quill,
        ContentBuilder,
        FileInput,
        PhotoBlock
    },
    layout: Layout,
    props: {
        meta: Object,
        permission: Object,
    },
    remember: 'form',
    setup(props) {
        const { meta, permission } = toRefs(props)
        const type = ref('edit')
        const title = ref(meta.value.title)
        const head = ref('Meta')
        const url = ref('/backend/metas')
        const createUrl = ref('')
        const content_builder_show = ref(false)
        const content_builder_status = (status) => {
            content_builder_show.value = status
            document.getElementsByTagName('html')[0].style.overflow = status ? "hidden" : "auto"
        }
        const swal = inject('$swal')
        const elm = ref(this)
        const form = useForm({

            slug: meta.value.slug,
            title: meta.value.title,
            description: meta.value.description,
            og_url: meta.value.og_url,
            og_title: meta.value.og_title,
            og_description: meta.value.og_description,
            og_image: meta.value.og_image,
            og_type: meta.value.og_type,
            og_pocale: meta.value.og_pocale,
            twitter_card: meta.value.twitter_card,
            twitter_title: meta.value.twitter_title,
            twitter_description: meta.value.twitter_description,
            twitter_image: meta.value.twitter_image,
            others: meta.value.others,
            status: meta.value.status,
        })
        const set_content = (data) => {
            form.page_layout_contents = data
        }
        const update = () => {
            swal({
                title: 'Are you sure?',
                text: "You want to proceed with this action",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
            }).then((result) => {
                if (result.isConfirmed) {
                    form.put(`${url.value}/${meta.value.id}`)
                    title.value = form.title
                    Swal.fire(
                        'Updated!',
                        'Your infomation has been Updated.',
                        'success'
                    )
                }
            });
        }

        const destroy = () => {
            swal({
                title: 'Are you sure?',
                text: "Do you really want to delete this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    form.delete(`${url.value}/${meta.value.id}`)
                    swal.fire(
                        'Deleted!',
                        'Your infomation has been deleted.',
                        'success'
                    )
                }
            });
        }
        const restore = () => {
            swal({
                title: 'Are you sure?',
                text: "You want to restore this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Resore it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    form.put(`${url.value}/${meta.value.id}/restore`)
                    Swal.fire(
                        'Restored!',
                        'Your infomation has been Restored.',
                        'success'
                    )
                }
            });
        }
        const statusCheck = () => {
            if (meta.value.status == 1) {
                form.status = true;
                return true;
            } else {
                form.status = false;
                return false;
            }
        }
        const set_photo = (val) => {
            form.og_image = val
        }
        const set_twitter_photo = (val) => {
            form.twitter_image = val
        }
        return {
            form,
            meta,
            type,
            title,
            head,
            url,
            createUrl,
            content_builder_show,
            set_content,
            content_builder_status,
            update,
            destroy,
            restore,
            statusCheck,
            swal,
            permission,
            set_photo,
            set_twitter_photo
        }
    },
    created: function () {
        this.statusCheck();
    }

}
</script>
<style>
.ql-toolbar .ql-formats .ql-image {
    display: none;
}
</style>
