<template>
    <TransitionRoot as="template" :show="galleryopen">
        <Dialog as="div" class="relative z-[9999]" @click="galleryopen = false">
            <div class="fixed inset-0" />
            <div class="fixed inset-0 overflow-hidden">
                <div class="absolute inset-0 overflow-hidden">
                    <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                        <TransitionChild as="template"
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enter-from="translate-x-full" enter-to="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leave-from="translate-x-0" leave-to="translate-x-full">
                            <DialogPanel class="pointer-events-auto w-screen max-w-md">
                                <form class="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                                    <div class="h-0 flex-1 overflow-y-auto">
                                        <div class="bg-indigo-700 px-4 py-6 sm:px-6">
                                            <div class="flex items-center justify-between">
                                                <DialogTitle class="text-base font-semibold leading-6 text-white">
                                                    Gallery</DialogTitle>
                                                <div class="ml-3 flex h-7 items-center">
                                                    <button type="button"
                                                        class="relative rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                                        @click="galleryopen = false">
                                                        <span class="absolute -inset-2.5" />
                                                        <span class="sr-only">Close panel</span>
                                                        <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="mt-1">
                                                <p class="text-sm text-indigo-300">Add gallery for this post.</p>
                                            </div>
                                        </div>
                                        <div class="flex flex-1 flex-col justify-between">
                                            <div class="divide-y divide-gray-200 px-4 sm:px-6">
                                                <div class="space-y-6 pb-5 pt-6">
                                                    <div
                                                        class="mt-1 rounded-md border border-gray-300 p-3 max-h-[485px] overflow-y-auto">

                                                        <div v-if="message"
                                                            class="flex items-center gap-2 message_block error"><i
                                                                class="fa fa-check"></i> {{ message }}<i
                                                                class="fa fa-times close_btn" @click="remove_msg"></i>
                                                        </div>


                                                        <form @submit.prevent="data_submit">
                                                            <div class="sm:col-span-2">
                                                                <label
                                                                    class="block text-sm font-medium text-gray-700 dark:text-gray-400">Pictute
                                                                    <span class="custom-error">*</span></label>
                                                                <div
                                                                    class="mt-1 flex flex-wrap justify-center rounded-md border-2 border-dashed border-gray-300 dark:border-black dark:bg-white dark:text-black px-6 pt-5 pb-6">
                                                                    <div class="space-y-1 text-center">
                                                                        <PhotoBlock :getPhoto="form.image_path"
                                                                            @SetPhoto="set_photo" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex flex-shrink-0 justify-end px-4 py-4" v-if="edit == false">
                                        <button type="button" @click="saveGalleryData()"
                                            class="ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Save</button>
                                    </div>
                                </form>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup>


import axios from 'axios';
import { ref, toRefs, inject, onMounted, watch } from 'vue'
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'

import TextInput from '@/Shared/TextInput'
import SelectInput from '@/Shared/SelectInput'
import StatusToogleButton from '@/Shared/StatusToogleButton'
import FileInput from '@/Shared/FileInput'
import { XMarkIcon } from '@heroicons/vue/24/outline'
import Button from '@/Shared/Button'
import PhotoBlock from './Photo';
import FloatingActionBtnFix from "@/Shared/Buttons/FloatingActionBtnFix"
import { LinkIcon, PlusIcon, QuestionMarkCircleIcon } from '@heroicons/vue/20/solid'
const props = defineProps(['galleries', 'galleryopen'])
const gallery_data = ref(props.galleries)
const ropen = ref(props.galleryopen)
const backendPrefix = ref(process.env.MIX_ADMIN_PREFIX)
const edit = ref(false)
const swal = inject('$swal')
const preloader = ref(false)

const message = ref('');
const form = useForm({
    image_path: null
})
const emit = defineEmits(['UpdateGalleryPopup', 'UpdateGallery'])

const save = () => {
    emit('UpdateGalleryPopup', props.galleryopen)
    emit('UpdateGallery', gallery_data)
}

const set_photo = (val) => {
    form.image_path = val
}
watch(() => props.galleryopen, () => {
    emit('UpdateGalleryPopup', props.galleryopen)
}, { deep: true })
watch(() => gallery_data, () => {
    emit('UpdateGallery', gallery_data)
}, { deep: true })

const saveGalleryData = (val) => {
    if (form.image_path != null) {
        gallery_data.value.push({
            id: null,
            post_id: null,
            image_path: form.image_path,
        })
        emit('UpdateGalleryPopup', false)
        form.image_path = null
    } else {
        message.value = 'Please fill mendatory filed'
    }

}


const remove_msg = () => {
    message.value = ''
}

</script>
<style lang="scss" scoped>
.message_block {
    position: fixed;
    z-index: 9999;
    left: -300px;
    bottom: 25px;
    color: #fff;
    height: 40px;
    padding: 0 15px;
    border-radius: 5px;
    transition: all 0.4s;

    &>.close_btn {
        position: absolute;
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        background-color: #941212;
        border: 1px solid #fff;
        border-radius: 50%;
        cursor: pointer;
        right: 5px;
        top: -10px;
        font-size: 8px;
        z-index: 5;
    }

    &.error {
        left: auto;
        right: 10%;
        background-color: #fc4949;
    }
}
</style>
