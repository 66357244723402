<template>
    <div class="gallery_view_block border-t border-gray-300 p-4 " v-if="galleries.length > 0">
        <Carousel :itemsToShow="3.95" :wrapAround="false" :transition="500">
            <Slide v-for="gallery in galleries" :key="gallery">
                <div class="carousel__item relative">
                    <img :src="gallery.image_path" alt="profile" class="h-[150px]">
                    <i class="fa-light fa-xmark right-0 text-red-700 top-1 absulate del"
                        @click="removeGallery(n_index, gallery.id)"></i>
                </div>
            </Slide>
            <template #addons>
                <Pagination />
                <Navigation />
            </template>
        </Carousel>
    </div>
    <div v-else class="w-full  border-t border-gray-300  p-4 col-span-2 italic">
        <span class="far fa-circle-info"></span>
        No Gallery Selected
    </div>
</template>

<script setup>
import axios from 'axios';
import { ref, toRefs, inject, onMounted, watch, defineComponent } from 'vue'
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import { Carousel, Pagination, Slide, Navigation } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
const backendPrefix = ref(process.env.MIX_ADMIN_PREFIX)
const props = defineProps(['galleries'])
const emit = defineEmits(['UpdateGalleryList'])
const gallery_list = props.galleries;
const removeGallery = (index, id = null) => {
    if (index !== -1) {
        if (id != null) {
            let data = {
                id: id
            }
            axios.post(`/${backendPrefix.value}/tourist-residents/destroy-gallery`, data).then(async (response) => { }).catch(error => {
            })
        }
        gallery_list.splice(index, 1);
        emit('UpdateGalleryList', gallery_list)
    }
}


</script>

<style lang="scss" scoped>
.gallery_view_block {

    :deep(.carousel__slide) {
        padding: 5px;
    }

    :deep(.carousel__viewport) {
        perspective: 2000px;
        overflow: visible !important;
    }

    :deep(.carousel__track) {
        transform-style: preserve-3d;
    }

    :deep(.carousel__slide--sliding) {
        transition: 0.5s;
    }

    :deep(.carousel__slide) {
        opacity: 0.9;
        transform: rotateY(-20deg) scale(0.9);
    }

    :deep(.carousel__slide--active~.carousel__slide) {
        transform: rotateY(20deg) scale(0.9);
    }

    :deep(.carousel__slide--prev) {
        opacity: 1;
        transform: rotateY(-10deg) scale(0.95);
    }

    :deep(.carousel__slide--next) {
        opacity: 1;
        transform: rotateY(10deg) scale(0.95);
    }

    :deep(.carousel__slide--active) {
        opacity: 1;
        transform: rotateY(0) scale(1.1);
    }

    :deep(i) {
        width: 30px;
        text-align: center;
        transition: all 0.4s;
        position: absolute;
        margin-right: 0px !important;
        top: 15px;
        font-size: 10px;
        display: none;
        cursor: pointer;

        &.active {
            display: flex !important;
        }

        &.del {
            position: absolute;
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #fff;
            border-radius: 50%;
            box-shadow: 0 0 5px #999;
            border: 1px solid #ddd;
            cursor: pointer;
            top: -5px;
            right: -5px;
        }
    }
}
</style>
