<template>
    <div>
        <Breadcrumbs :type="type" :header="title" :url="url"></Breadcrumbs>
        <div class="max-w-5xl bg-white rounded-md shadow overflow-hidden">
            <form @submit.prevent="store">
                <div class="flex flex-wrap -mb-8 -mr-6 p-8">
                    <text-input v-model="form.title" :error="form.errors.title" class="pb-8 pr-6 w-full lg:w-1/1"
                        @keyup="slugBuild" label="Content Headline" required="true"
                        placeholder="i.e BANGSAMORO BUDGET FORUM F.Y. 2023" />
                    <text-input v-model="form.slug" :error="form.errors.slug" class="pb-8 pr-6 w-full lg:w-1/2"
                        label="Slug" required="true" placeholder="i.e bangsamoro-budget-forum" />
                    <text-input v-model="form.video_url" :error="form.errors.video_url"
                        class="pb-8 pr-6 w-full lg:w-1/2" label="Video Url" required="true" placeholder="i.e " />
                    <div class="pb-8 pr-6 w-full lg:w-1/1">
                        <label class="form-label" for="details">Description</label>
                        <vue-editor v-model="form.details" />
                    </div>
                    <file-input v-model="form.photo" :error="form.errors.photo" class="pb-8 pr-6 w-full lg:w-1/1"
                        type="file" accept="image/*" label="Photo" />
                    <status-toogle-button v-model="form.status" :error="form.errors.status" label="Content Status"
                        class="pb-8 pr-6 w-full lg:w-1/1"></status-toogle-button>
                </div>
                <Button :type="type" :name="title" :url="createUrl" :loading="form.processing"></Button>
            </form>
        </div>
    </div>
</template>

<script>
import Breadcrumbs from '@/Shared/Breadcrumbs'
import Button from '@/Shared/Button'
import FileInput from '@/Shared/FileInput'
import Layout from '@/Shared/Layout'
import LoadingButton from '@/Shared/LoadingButton'
import SelectInput from '@/Shared/SelectInput'
import StatusToogleButton from '@/Shared/StatusToogleButton'
import TextareaInput from '@/Shared/TextareaInput'
import TextInput from '@/Shared/TextInput'
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import { ref, toRefs, inject } from 'vue'
import { Quill, VueEditor } from "vue3-editor"
export default {
    components: {
        Head,
        Link,
        LoadingButton,
        SelectInput,
        TextInput,
        Breadcrumbs,
        StatusToogleButton,
        Button,
        TextareaInput,
        Quill,
        VueEditor,
        FileInput
    },
    layout: Layout,
    remember: 'form',
    props: {
        newsCat: Array
    },
    setup(props) {
        const { newsCat } = toRefs(props)
        const form = useForm({
            title: null,
            slug: null,
            details: null,
            status: true,
            on_headline: 0,
            video_url: null,
            photo: null
        })
        const type = ref('create')
        const title = ref('Content')
        const url = ref('/backend/queue-contents')
        const createUrl = ref('')
        const swal = inject('$swal')
        const store = () => {
            swal({
                title: 'Are you sure?',
                text: "You want to proceed with this action",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
            }).then((result) => {
                if (result.isConfirmed) {
                    form.post(`${url.value}`, { forceFormData: true, })
                    Swal.fire(
                        'Added!',
                        'Your infomation has been Added.',
                        'success'
                    )
                }
            });

        }
        const slugBuild = () => {
            form.slug = form.title.replace(/\s/g, '-').toLowerCase()
        }
        const onChangeType = (event) => {
            if (event.target.value == 'on') form.external_url = null;
            if (event.target.value == 'on') form.details = null;
            if (event.target.value == 'on') form.photo = null;
        }
        return {
            form,
            type,
            title,
            url,
            createUrl,
            store,
            slugBuild,
            onChangeType,
            newsCat
        }
    }
}
</script>
