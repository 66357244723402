<template>
    <div class="profile_edit_content_block">
        <Breadcrumbs :type="type" :header="title" :url="url" :head="head"></Breadcrumbs>
        <trashed-message v-if="counter.deleted_at" class="mb-6" @restore="restore"> This data has been
            deleted.
        </trashed-message>
        <div class="max-w-2xl bg-white rounded-md shadow overflow-hidden">
            <form @submit.prevent="update">
                <div class="grid grid-cols-1 gap-y-4 gap-x-4 sm:grid-cols-4 mt-3 p-4">
                    <text-input v-model="form.title" :error="form.errors.title" class="sm:col-span-2" label="Name"
                        required="true" placeholder="i.e." />
                    <text-input v-model="form.slug" :error="form.errors.slug" class="sm:col-span-2" label="Counter Slug"
                        required="true" placeholder="i.e " />
                    <text-input v-model="form.counter_number" :error="form.errors.counter_number" class="sm:col-span-2"
                        label="Counter Number" required="true" placeholder="i.e 1" />
                    <select-input v-model="form.queue_office_id" :error="form.errors.queue_office_id"
                        class="sm:col-span-2" label="Office" required="true">
                        <option value=''>Select Office</option>
                        <option v-for="office in offices" :key="office.id" :value='office.id'>{{ office.office_name }}
                        </option>
                    </select-input>
                    <status-toogle-button v-model="form.priority_lane" :error="form.errors.priority_lane"
                        label="Priority Lane" class="flex items-center sm:col-span-2 "></status-toogle-button>
                    <div class="sm:col-span-2" v-if="form.priority_lane == true">
                        <Multiselect v-model="form.priority_leans" mode="tags" placeholder="Select Lenes"
                            :options="priorityLanes" :limit="20" :search="true" />
                    </div>
                    <status-toogle-button v-model="form.status" :error="form.errors.status" label="Status"
                        class="flex items-center sm:col-span-2"></status-toogle-button>
                </div>
                <div class="flex items-center px-8 py-4 bg-gray-50 border-t border-gray-100">
                    <Button v-if="permission.edit > 0" :type="type" :name="head" :url="createUrl"
                        :loading="form.processing"></Button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import { ref, toRefs, inject, onMounted, watch } from 'vue'
import axios from 'axios';
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import TextareaInput from '@/Shared/TextareaInput'
import TextDatePicker from '@/Shared/TextDatePicker'
import TextInputEmail from '@/Shared/TextInputEmail'
import SelectInput from '@/Shared/SelectInput'
import LoadingButton from '@/Shared/LoadingButton'
import TrashedMessage from '@/Shared/TrashedMessage'
import StatusToogleButton from '@/Shared/StatusToogleButton'
import Breadcrumbs from '@/Shared/Breadcrumbs'
import DeleteIcon from '@/Shared/DeleteIcon'
import Button from '@/Shared/Button'
import Multiselect from '@vueform/multiselect'
export default {
    components: {
        Head,
        Icon,
        Link,
        LoadingButton,
        SelectInput,
        TextInput,
        TrashedMessage,
        StatusToogleButton,
        Breadcrumbs,
        DeleteIcon,
        Button,
        TextareaInput,
        TextDatePicker,
        TextInputEmail,
        Multiselect
    },
    layout: Layout,
    props: {
        counter: Object,
        permission: Object,
        priorityLanes: Array,
        offices: Array
    },
    remember: 'form',
    setup(props) {
        const { counter, permission, priorityLanes, offices } = toRefs(props)
        const type = ref('edit')
        const title = ref(counter.value.name)
        const head = ref('Counter')
        const url = ref('/backend/queue-counters')
        const createUrl = ref('')
        const swal = inject('$swal')
        const elm = ref(this)
        const form = useForm({
            title: counter.value.title,
            slug: counter.value.slug,
            counter_number: counter.value.counter_number,
            status: counter.value.status,
            priority_lane: counter.value.priority_lane,
            priority_leans: counter.value.priority_leans,
            queue_office_id: counter.value.queue_office_id,
            agent_id: counter.value.agent_id
        })
        const update = () => {
            swal({
                title: 'Are you sure?',
                text: "You want to proceed with this action",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
            }).then((result) => {
                if (result.isConfirmed) {
                    form.put(`${url.value}/${counter.value.id}`)
                    title.value = form.name
                    swal.fire(
                        'Updated!',
                        'Your infomation has been Updated.',
                        'success'
                    )
                }
            });
        }
        const destroy = () => {
            swal({
                title: 'Are you sure?',
                text: "Do you really want to delete this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    form.delete(`${url.value}/${counter.value.id}`)
                    swal.fire(
                        'Deleted!',
                        'Your infomation has been deleted.',
                        'success'
                    )
                }
            });
        }
        const restore = () => {
            swal({
                title: 'Are you sure?',
                text: "You want to restore this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Resore it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    form.put(`${url.value}/${counter.value.id}/restore`)
                    swal.fire(
                        'Restored!',
                        'Your infomation has been Restored.',
                        'success'
                    )
                }
            });
        }
        const set_photo = (val) => {
            form.icon_img = val
        }

        return {
            form,
            counter,
            type,
            title,
            head,
            url,
            createUrl,
            update,
            destroy,
            restore,
            swal,
            permission,
            set_photo,
            priorityLanes,
            offices,
        }
    }
}
</script>
<style lang="scss" scoped>
$button_height: 36;

.profile_edit_content_block {
    display: block;

    .edit_form_block {
        background-color: #ffffff80;

        input,
        textarea,
        select {
            width: 100%;
            border: 1px solid #ddd;
            padding: 5px 10px;
            border-radius: 5px;
        }

        i {
            display: none;
            width: 30px;
            text-align: center;
            transition: all 0.4s;
            color: #888;
            position: absolute;
            margin-left: 265px !important;
            margin-top: -23px;

            &.active {
                display: block;
            }
        }

        .err_msg {
            border-color: #CD0000 !important
        }
    }
}

.save_btn,
.cancel_btn {
    height: #{$button_height}px;
    background-color: #0f8050;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;

    &.cancel_btn {
        border: 1px solid #CD0000;
        color: #CD0000;
        background-color: #fff;
    }
}


.dark {
    .profile_edit_content_block {

        input,
        textarea,
        select {
            background-color: #222;
        }
    }
}

.password_block,
.confirm_password_block {
    p {
        align-items: baseline;
        color: #666;
        display: flex;
        gap: 5px;
        font-size: 10px !important;

        span {
            color: gold !important;
            font-size: 10px !important;
        }
    }
}
</style>
