<template>
    <div>
        <Breadcrumbs :type="type" :header="title" :url="url"></Breadcrumbs>
        <div class="flex items-center justify-between mb-6">
            <search-filter v-model="form.search" class="mr-4 w-full max-w-xl" @reset="reset">
                <template v-slot:filter>
                    <select v-model="form.trashed" class="form-select  w-28">
                        <option :value="null">Filter</option>
                        <option value="with">With Trashed</option>
                        <option value="only">Only Trashed</option>
                    </select>
                </template>
                <template v-slot:page>
                    <select v-model="form.length" class="form-select  w-20">
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="15">15</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="75">75</option>
                        <option value="100">100</option>
                        <option value="150">150</option>
                        <option value="200">200</option>
                    </select>
                </template>
                <template v-slot:status>
                    <div class="flex w-40  bg-white rounded shadow">
                        <input class="relative px-4 py-2 w-full rounded-r focus:none" autocomplete="off" type="text"
                            name="designation" placeholder="Designation" v-model="form.designation" />
                    </div>
                    <div class="flex w-40  bg-white rounded shadow">
                        <input class="relative px-4 py-2 w-full rounded-r focus:none" autocomplete="off" type="text"
                            name="office" placeholder="Office" v-model="form.office" />
                    </div>
                    <select v-model="form.status" class="form-select w-32">
                        <option :value="null">Status</option>
                        <option value="1">Active</option>
                        <option value="2">Inactive</option>
                    </select>
                </template>
            </search-filter>
            <Button :type="type" :name="title" :url="createUrl" v-if="permission.add > 0"></Button>
        </div>
        <div class="flex flex-wrap w-full">
            <form  @submit.prevent="upload" method="POST" enctype="multipart/form-data" class="w-full">
                <file-input v-model="formData.file" :error="formData.errors.file" class=" w-full" type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" label="Bulk Upload" />
                <div class="flex items-center justify-end bg-gray-50 border-t border-gray-100">
                    <div class="flex items-center justify-end px-8 py-4 bg-gray-50 border-t border-gray-100">
                        <a href="../sample_file/EmployeeUpload.xlsx" class="flex items-center btn-indigo" download _target="blank"><i class="fa fa-download"></i> Download Sample</a>
                    </div>
                    <Button v-if="permission.add>0" :type="'create'" :name="title" :url="''" :loading="form.processing"></Button>
                </div>
            </form>
        </div>
        <div class="bg-white rounded-md shadow overflow-x-auto">
            <table class="w-full whitespace-nowrap">
                <thead>
                    <tr class="text-left font-bold">
                        <th class="pb-2 pt-3 px-3">Employee Name</th>
                        <th class="pb-2 pt-3 px-3">Status</th>
                        <th class="pb-2 pt-3 px-3">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="employee in officeemployees.data" :key="employee.id"
                        class="hover:bg-gray-100 focus-within:bg-gray-100">
                        <td class="border-t ">
                            <div class="px-3 py-1">

                                <div class="flex items-center">
                                    <div class="p-2 inline-block align-middle ">
                                        <img v-if="employee.picture" class="block w-8 h-8 rounded-full "
                                            :src="employee.picture" />
                                        <img v-else src="/images/profile.png" alt="profile" class=" w-8 h-8 rounded-full ">
                                    </div>
                                    <div class="pl-3">
                                        <h4 class="flex items-center">
                                            <div class="font-bold">{{ employee.first_name }}&nbsp;{{ employee.middle_name
                                            }}&nbsp;{{ employee.last_name }}&nbsp;{{ employee.surfix }}</div>
                                        </h4>
                                        <p>Office:
                                            {{ employee.des_data ? employee.des_data.office.office_name : null }}
                                            {{ employee.des_data ? employee.des_data.geo_name : null }}
                                        </p>
                                        <p>Designation: {{ employee.des_data &&
                                            employee.des_data.designation ? employee.des_data.designation.designation_name : null
                                        }}</p>
                                    </div>
                                </div>



                                <icon v-if="employee.deleted_at" name="trash"
                                    class="flex-shrink-0 ml-2 w-3 h-3 fill-gray-400" />
                            </div>
                        </td>
                        <td class="border-t">
                            <status-level :status="employee.status"></status-level>
                        </td>
                        <td class="w-px border-t">
                            <div class="flex items-center px-2" v-if="permission.edit > 0">
                                <Link class="px-4 py-2" :href="`/backend/employees/${employee.id}/edit`" tabindex="-1">
                                <i class="fa fa-edit"></i>
                                </Link>
                            </div>
                        </td>
                    </tr>
                    <tr v-if="officeemployees.data.length === 0">
                        <td class="px-3 py-1 border-t" colspan="4">No Employee found.</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <pagination class="mt-6" :links="officeemployees.links" />
    </div>
</template>

<script>
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import { ref, toRefs, inject } from 'vue'
import Icon from '@/Shared/Icon'
import pickBy from 'lodash/pickBy'
import Layout from '@/Shared/Layout'
import throttle from 'lodash/throttle'
import mapValues from 'lodash/mapValues'
import Pagination from '@/Shared/Pagination'
import SearchFilter from '@/Shared/SearchFilter'
import StatusLevel from '@/Shared/StatusLevel'
import Breadcrumbs from '@/Shared/Breadcrumbs'
import TextInput from '@/Shared/TextInput'
import Button from '@/Shared/Button'
import FileInput from '@/Shared/FileInput'
export default {
    components: {
        Head,
        Icon,
        Link,
        Pagination,
        SearchFilter,
        StatusLevel,
        Breadcrumbs,
        Button,
        FileInput
    },
    layout: Layout,
    props: {
        filters: Object,
        officeemployees: Object,
        permission: Object,
    },
    setup(props) {
        const { filters, officeemployees, permission } = toRefs(props)
        const form = ref({
            search: filters.value.search,
            designation: filters.value.designation,
            office: filters.value.office,
            trashed: filters.value.trashed,
            length: filters.value.length ? filters.value.length : 15,
            status: filters.value.status,
        })

        const formData = useForm({
            file: null,
        })
        const type = ref('index')
        const title = ref('Employee')
        const url = ref('/backend/employees/bulk/import')
        const createUrl = ref('/backend/employees/create')
        const reset = () => {
            form.value = mapValues(form, () => null)
        }
        const swal = inject('$swal')
        const upload = () => {
        swal({
            title: 'Are you sure?',
            text: "You want to proceed with this action",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
          }).then((result) => {
            if (result.isConfirmed) {
              formData.post(`${url.value}`, {forceFormData: true,})
              Swal.fire(
                'Updated!',
                'Your infomation has been Updated.',
                'success'
              )
            }
          });
      }
        return {
            filters,
            officeemployees,
            form,
            type,
            title,
            url,
            createUrl,
            reset,
            permission,
            formData,
            upload
        }
    },
    watch: {
        form: {
            deep: true,
            handler: throttle(function () {
                this.$inertia.get('/backend/employees', pickBy(this.form), { preserveState: true })
            }, 150),
        },
    }
}
</script>
