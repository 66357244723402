<template>
    <TransitionRoot as="template" :show="designationopen">
        <Dialog as="div" class="relative z-[9999]" @click="update_popup(false)">
            <div class="fixed inset-0" />
            <div class="fixed inset-0 overflow-hidden">
                <div class="absolute inset-0 overflow-hidden">
                    <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                        <TransitionChild as="template"
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enter-from="translate-x-full" enter-to="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leave-from="translate-x-0" leave-to="translate-x-full">
                            <DialogPanel class="pointer-events-auto w-screen max-w-md">
                                <form class="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                                    <div class="h-0 flex-1 overflow-y-auto">
                                        <div class="bg-indigo-700 px-4 py-6 sm:px-6">
                                            <div class="flex items-center justify-between">
                                                <DialogTitle class="text-base font-semibold leading-6 text-white">
                                                    Designation</DialogTitle>
                                                <div class="ml-3 flex h-7 items-center">
                                                    <button type="button"
                                                        class="relative rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                                        @click="update_popup(false)">
                                                        <span class="absolute -inset-2.5" />
                                                        <span class="sr-only">Close panel</span>
                                                        <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="mt-1">
                                                <p class="text-sm text-indigo-300">Assign Designation To Employee.</p>
                                            </div>
                                        </div>
                                        <div class="flex flex-1 flex-col justify-between">
                                            <div class="divide-y divide-gray-200 px-4 sm:px-6">
                                                <div class="space-y-6 pb-5 pt-6">
                                                    <div
                                                        class="mt-1 rounded-md border border-gray-300 p-3 max-h-[485px] overflow-y-auto">

                                                        <div v-if="message"
                                                            class="flex items-center gap-2 message_block error"><i
                                                                class="fa fa-check"></i> {{ message }}<i
                                                                class="fa fa-times close_btn" @click="remove_msg"></i>
                                                        </div>
                                                        <EmployeeGeoMap :regions="regions" :provinces="provinces"
                                                            :officelayers="officelayers" :employee_id="employee_id"
                                                            :ministries="ministries"
                                                            :employee_geo_map="employee_geo_map"
                                                            @getOrganograms="get_organogram"
                                                            @getGeoInfos="get_geo_infos" />
                                                        <EmployeeOrganogramBlock v-if="(organograms.length > 0)"
                                                            :designation_ids="designation_ids" :emp_id="employee_id"
                                                            :geo_infos="geoInfos" :permission="permission"
                                                            :organogram="organograms"
                                                            @UpdateDesignation="update_designation_popup" />

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </form>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup>


import axios from 'axios';
import { ref, toRefs, inject, onMounted, watch } from 'vue'
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'

import TextInput from '@/Shared/TextInput'
import SelectInput from '@/Shared/SelectInput'
import StatusToogleButton from '@/Shared/StatusToogleButton'
import FileInput from '@/Shared/FileInput'
import { XMarkIcon } from '@heroicons/vue/24/outline'
import Button from '@/Shared/Button'
import Vue3IconPicker from '@/Shared/components/Vue3IconPicker'
import FloatingActionBtnFix from "@/Shared/Buttons/FloatingActionBtnFix"
import EmployeeGeoMap from './EmployeeGeoMap'
import EmployeeOrganogramBlock from "./EmployeeOrganogram"
import { LinkIcon, PlusIcon, QuestionMarkCircleIcon } from '@heroicons/vue/20/solid'

const props = defineProps(['designationopen', 'regions', 'provinces', 'officelayers', 'employee_id', 'ministries', 'employee_geo_map', 'designation_ids', 'permission'])


const { regions, provinces, employee_id, ministries, employee_geo_map, designation_ids, permission } = toRefs(props)

const backendPrefix = ref(process.env.MIX_ADMIN_PREFIX)
const edit = ref(false)
const swal = inject('$swal')
const preloader = ref(false)
const ispackage = ref(false)
const message = ref('');
const organograms = ref([])
const geoInfos = ref({})
const emit = defineEmits(['UpdateDesignationPopup '])
const update_popup = (status) => {
    emit('UpdateDesignationPopup', status)
}
const remove_msg = () => {
    message.value = ''
}
const get_geo_infos = (obj) => {
    geoInfos.value = obj
}
const get_organogram = (data) => {
    organograms.value = data
    if (employee_geo_map.value != null) {
        if (employee_geo_map.value.DesignationIds) {
            employee_geo_map.value.DesignationIds.forEach(v => {
                designation_ids.value[v.designation_id] = v.designation_id
            })
        }
    }
}
const update_designation_popup = (val) => {
    emit('UpdateDesignationPopup', val)
}
onMounted(() => {

})
</script>
<style lang="scss" scoped>
.message_block {
    position: fixed;
    z-index: 9999;
    left: -300px;
    bottom: 25px;
    color: #fff;
    height: 40px;
    padding: 0 15px;
    border-radius: 5px;
    transition: all 0.4s;

    &>.close_btn {
        position: absolute;
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        background-color: #941212;
        border: 1px solid #fff;
        border-radius: 50%;
        cursor: pointer;
        right: 5px;
        top: -10px;
        font-size: 8px;
        z-index: 5;
    }

    &.error {
        left: auto;
        right: 10%;
        background-color: #fc4949;
    }
}
</style>
