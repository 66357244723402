<template>
    <div>
        <Breadcrumbs :type="type" :header="title" :url="url"></Breadcrumbs>
        <div class="max-w-5xl bg-white rounded-md shadow overflow-hidden">
            <form @submit.prevent="store">
                <div class="flex flex-wrap -mb-8 -mr-6 p-8">
                    <text-input v-model="form.question" :error="form.errors.question" class="pb-8 pr-6 w-full lg:w-1/1"
                        label="Question" required="true" placeholder="i.e How to use this website?" />
                    <div class="pb-8 pr-6 w-full lg:w-1/1">
                        <label class="form-label">Tags</label>
                        <input-tag-select :tag_info="form.tag" @setUpdateTags="set_update_tags" />
                    </div>
                    <div class="pb-8 pr-6 w-full lg:w-1/1">
                        <label class="form-label" for="answer">Description<span class="custom-error">*</span></label>
                        <vue-editor v-model="form.answer" />
                    </div>
                    <status-toogle-button v-model="form.status" :error="form.errors.status" label="Status"
                        class="pb-8 pr-6 w-full lg:w-1/2"></status-toogle-button>
                </div>
                <Button :type="type" :name="question" :url="createUrl" :loading="form.processing"></Button>
            </form>
        </div>
    </div>
</template>

<script>
import Breadcrumbs from '@/Shared/Breadcrumbs'
import Button from '@/Shared/Button'
import Layout from '@/Shared/Layout'
import LoadingButton from '@/Shared/LoadingButton'
import SelectInput from '@/Shared/SelectInput'
import InputTagSelect from '@/Shared/InputTagSelect'
import StatusToogleButton from '@/Shared/StatusToogleButton'
import TextInput from '@/Shared/TextInput'
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import { ref, toRefs, inject } from 'vue'
import { Quill, VueEditor } from "vue3-editor"
export default {
    components: {
        Head,
        Link,
        LoadingButton,
        SelectInput,
        TextInput,
        Breadcrumbs,
        StatusToogleButton,
        Button,
        Quill,
        VueEditor,
        InputTagSelect
    },
    layout: Layout,
    remember: 'form',
    setup() {
        const form = useForm({
            question: null,
            answer: null,
            tag: [],
            status: true
        })
        const type = ref('create')
        const title = ref('Chat')
        const url = ref('/backend/chats')
        const createUrl = ref('')
        const swal = inject('$swal')
        const store = () => {
            swal({
                question: 'Are you sure?',
                text: "You want to proceed with this action",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
            }).then((result) => {
                if (result.isConfirmed) {
                    form.post(`${url.value}`, { forceFormData: true, })
                    Swal.fire(
                        'Added!',
                        'Your infomation has been Added.',
                        'success'
                    )
                }
            });

        }
        const set_update_tags = (obj) => {
            form.tag = obj
        }
        return {
            form,
            type,
            title,
            url,
            createUrl,
            store,
            set_update_tags
        }
    }
}
</script>
