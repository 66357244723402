<template>
    <div>
        <Breadcrumbs :type="type" :header="title" :url="url"></Breadcrumbs>
        <div class="max-w-5xl bg-white rounded-md shadow overflow-hidden">
            <form @submit.prevent="store">
                <div class="flex flex-wrap -mb-8 -mr-6 p-8">
                    <div class="pb-8 pr-6 w-full lg:w-1/2">
                        <div class="mt-1 flex items-center h-[225px]">
                            <div
                                class="mt-1 flex flex-wrap justify-center rounded-md border-2 border-dashed border-gray-300 dark:border-black dark:bg-white dark:text-black px-6 pt-5 pb-6">
                                <div class="space-y-1 text-center">
                                    <PhotoBlock :getPhoto="form.og_image" @SetPhoto="set_photo" />
                                    <span>Og Image</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="pb-8 pr-6 w-full lg:w-1/2">
                        <div class="mt-1 flex items-center h-[225px]">
                            <div
                                class="mt-1 flex flex-wrap justify-center rounded-md border-2 border-dashed border-gray-300 dark:border-black dark:bg-white dark:text-black px-6 pt-5 pb-6">
                                <div class="space-y-1 text-center">
                                    <PhotoBlock :getPhoto="form.twitter_image" @SetPhoto="set_twitter_photo" />
                                    <span>Twitter Image</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <text-input v-model="form.title" :error="form.errors.title" class="pb-8 pr-6 w-full lg:w-1/2"
                        label="Title of Meta" placeholder="i.e " />
                    <text-input v-model="form.slug" :error="form.errors.slug" class="pb-8 pr-6 w-full lg:w-1/2"
                        label="Slug of Meta" placeholder="i.e " />
                    <textarea-input v-model="form.description" :error="form.errors.description"
                        class="pb-8 pr-6 w-full lg:w-1/1" label="Description of Meta" placeholder="i.e " />
                    <text-input v-model="form.og_url" :error="form.errors.og_url" class="pb-8 pr-6 w-full lg:w-1/2"
                        label="Og Url" placeholder="i.e " />
                    <text-input v-model="form.og_title" :error="form.errors.og_title" class="pb-8 pr-6 w-full lg:w-1/2"
                        label="Og Title" placeholder="i.e " />
                    <textarea-input v-model="form.og_description" :error="form.errors.og_description"
                        class="pb-8 pr-6 w-full lg:w-1/1" label="Og Description" placeholder="i.e " />
                    <text-input v-model="form.og_type" :error="form.errors.og_type" class="pb-8 pr-6 w-full lg:w-1/2"
                        label="Og Type" required="true" placeholder="i.e " />
                    <text-input v-model="form.og_pocale" :error="form.errors.og_pocale"
                        class="pb-8 pr-6 w-full lg:w-1/2" label="Og Pocale" required="true" placeholder="i.e " />
                    <text-input v-model="form.twitter_card" :error="form.errors.twitter_card"
                        class="pb-8 pr-6 w-full lg:w-1/2" label="Twitter Card" required="true" placeholder="i.e " />
                    <text-input v-model="form.twitter_title" :error="form.errors.twitter_title"
                        class="pb-8 pr-6 w-full lg:w-1/2" label="Twitter Title" required="true" placeholder="i.e " />
                    <textarea-input v-model="form.twitter_description" :error="form.errors.twitter_description"
                        class="pb-8 pr-6 w-full lg:w-1/1" label="Twitter Description" placeholder="i.e " />

                    <textarea-input v-model="form.others" :error="form.errors.others" class="pb-8 pr-6 w-full lg:w-1/1"
                        label="Others" placeholder="i.e " />
                    <status-toogle-button v-model="form.status" :error="form.errors.status" label="Meta Status"
                        class="pb-8 pr-6 w-full lg:w-1/1"></status-toogle-button>
                </div>
                <Button :type="type" :name="title" :url="createUrl" :loading="form.processing"></Button>
            </form>
        </div>
    </div>
</template>

<script>
import Breadcrumbs from '@/Shared/Breadcrumbs'
import Button from '@/Shared/Button'
import Layout from '@/Shared/Layout'
import LoadingButton from '@/Shared/LoadingButton'
import SelectInput from '@/Shared/SelectInput'
import StatusToogleButton from '@/Shared/StatusToogleButton'
import TextareaInput from '@/Shared/TextareaInput'
import TextInput from '@/Shared/TextInput'
import FileInput from '@/Shared/FileInput'
import PhotoBlock from './Photo';
import ContentBuilder from '@/Pages/Admin/ContentBuilder/Index'
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import { ref, toRefs, inject } from 'vue'
import { Quill, VueEditor } from "vue3-editor"
export default {
    components: {
        Head,
        Link,
        LoadingButton,
        SelectInput,
        TextInput,
        Breadcrumbs,
        StatusToogleButton,
        Button,
        TextareaInput,
        VueEditor,
        Quill,
        ContentBuilder,
        FileInput,
        PhotoBlock
    },
    layout: Layout,
    remember: 'form',

    setup(props) {
        const form = useForm({
            slug: null,
            title: null,
            description: null,
            og_url: null,
            og_title: null,
            og_description: null,
            og_image: null,
            og_type: null,
            og_pocale: null,
            twitter_card: null,
            twitter_title: null,
            twitter_description: null,
            twitter_image: null,
            others: null,
            status: true
        })
        const ogImageData = ref('create')
        const type = ref('create')
        const title = ref('Meta')
        const url = ref('/backend/metas')
        const createUrl = ref('')
        const content_builder_show = ref(false)
        const content_builder_status = (status) => {
            content_builder_show.value = status
            document.getElementsByTagName('html')[0].style.overflow = status ? "hidden" : "auto"
        }
        const set_content = (data) => {
            form.page_layout_contents = data
        }
        const swal = inject('$swal')
        const store = () => {
            swal({
                title: 'Are you sure?',
                text: "You want to proceed with this action",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
            }).then((result) => {
                if (result.isConfirmed) {
                    form.post(`${url.value}`, { forceFormData: true, })
                    Swal.fire(
                        'Added!',
                        'Your infomation has been Added.',
                        'success'
                    )
                }
            });

        }
        const set_photo = (val) => {
            form.og_image = val
        }
        const set_twitter_photo = (val) => {
            form.twitter_image = val
        }
        return {
            form,
            type,
            title,
            url,
            createUrl,
            content_builder_show,
            content_builder_status,
            set_content,
            store,
            set_photo,
            set_twitter_photo
        }
    }
}
</script>
<style>
.ql-toolbar .ql-formats .ql-image {
    display: none;
}
</style>
